import axios from "axios";
export default function request(config) {
    const instance = axios.create({
        baseURL: "https://www.dixinbao.cn/api",
        timeout: 5000,
        headers: {
            type: 1,
            token: sessionStorage.getItem("token") ? sessionStorage.getItem("token") : "",
        },
    });
    instance.interceptors.request.use(
        (config) => {
            return config;
        },
        (err) => {
            console.log(err);
        }
    );
    instance.interceptors.response.use(
        (res) => {
            return res.data;
        },
        (err) => {
            if (err.response.status == 401) {
                this.$bus.emit("noLogin");
            }
        }
    );
    return instance(config);
}