<template>
  <div id="app">
    <router-view />
    <NoLogin v-if="isShow"></NoLogin>
  </div>
</template>
<script>
import NoLogin from "./components/NoLogin.vue";
import TIM from "tim-js-sdk";
import TIMUploadPlugin from "tim-upload-plugin"; // 使用前请将 IM SDK 升级到v2.9.2或更高版本
export default {
  data() {
    return {
      isShow: false,
    };
  },
  components: {
    NoLogin,
  },
  created() {
    console.log(
      !sessionStorage.getItem("token"),
      this.$route.path,
      this.$route.path == "/index",
      'this.$route.path == "/index"'
    );
    if (this.$route.path == "/" && !sessionStorage.getItem("token")) {
      this.isShow = true;
    }
  },
  mounted() {
    console.log("appMounted");
    this.$bus.on("noLogin", () => {
      this.isShow = true;
      console.log(this.isShow, "jieshou");
    });
    this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, (event) => {
      console.log(event, "收到推送的消息");
      this.$bus.emit("message", event);
    });
    this.tim.on(TIM.EVENT.SDK_NOT_READY, function (event) {
      console.log(
        event,
        "收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作"
      );
      this.$bus.emit("down", event);
      this.$message.error("您的帐号已下线");
    });

    this.tim.on(TIM.EVENT.KICKED_OUT, function (event) {
      console.log(event, "收到被踢下线通知");
      this.$message.error("您的帐号已被挤下线");
    });
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
/deep/.el-dialog__header {
  padding: 0 !important;
}
</style>
