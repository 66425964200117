import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
    //引入Element-Ui组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import { Message } from 'element-ui';
Vue.prototype.$message = Message;
Vue.use(ElementUI);

//引入网络请求
import request from './network/index'
Vue.prototype.$http = request

//引入事件总线
import VueBus from 'vue-bus';
Vue.use(VueBus);


// 引入TIM聊天
import TIM from "tim-js-sdk";
import COS from "cos-js-sdk-v5";
import TIMUploadPlugin from 'tim-upload-plugin';

let options = {
    SDKAppID: 1400693294 // 接入时需要将0替换为您的即时通信应用的 SDKAppID
};
let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用


// tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用
tim.registerPlugin({ 'cos-js-sdk': COS });
// 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
// Vue.use(VueQuillEditor, /* { default global options } */ )
Vue.prototype.TIM = TIM
Vue.prototype.tim = tim



//地图
import VueAMap from "vue-amap"
Vue.use(VueAMap)

VueAMap.initAMapApiLoader({
    key: "8e22b47d34de3b9f1cd3130212d875ad",
    plugin: [
        "AMap.Autocomplete", //输入提示插件
        "AMap.PlaceSearch", //POI搜索插件
        "AMap.Scale", //右下角缩略图插件 比例尺
        "AMap.OverView", //地图鹰眼插件
        "AMap.ToolBar", //地图工具条
        "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
        "AMap.Geocoder" // 逆地理编码,通过经纬度获取地址所在位置详细信息
        // 根据需求选用
    ],
    uiVersion: "1.0", // 地图ui版本
    v: '1.4.4' // amap版本
})

window._AMapSecurityConfig = {
    securityJsCode: 'ccb6b6aaf1cb9f007d30315b88e3c299',
}


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')