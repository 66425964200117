import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 首页
const Index = () =>
    import ("../views/Index/Index.vue");
// 个人中心
const PersonalCenter = () =>
    import ("../views/PersonalCenter/PersonalCenter.vue");
//账号与安全
const AccountSecurity = () =>
    import ("../views/AccountSecurity/AccountSecurity.vue");
//职位
const Position = () =>
    import ("../views/Position/Position.vue");
// 职位详情
const PositionDetails = () =>
    import ("../views/PositionDetails/PositionDetails.vue");
//公司
const Company = () =>
    import ("../views/Company/Company.vue");
//公司介绍
const CompanyIntroduction = () =>
    import ("../views/CompanyIntroduction/CompanyIntroduction.vue");
//沟通过、已投递、面试、感兴趣
const DitchPassage = () =>
    import ("../views/DitchPassage/DitchPassage.vue");
//在线简历页面
const OnlineResume = () =>
    import ("../views/OnlineResume/OnlineResume.vue");
//对我感兴趣、看过我
const InterestedInMe = () =>
    import ("../views/InterestedInMe/InterestedInMe.vue");
//用户协议
const UserAgreement = () =>
    import ("../views/UserAgreement/UserAgreement.vue");
//资质公示
const Publicity = () =>
    import ("../views/Publicity/Publicity.vue");
//消息
const Message = () =>
    import ("../views/Message/Message.vue");
const routes = [{
        path: "/",
        name: "home",
        component: Index,
    },
    {
        //首页
        path: "/index",
        name: "Index",
        component: Index,
    },
    {
        //个人中心
        path: "/personalcenter",
        name: "PersonalCenter",
        component: PersonalCenter,
    },
    {
        //账号安全
        path: "/accountsecurityc",
        name: "AccountSecurity",
        component: AccountSecurity,
    },
    {
        //职位
        path: "/position",
        name: "Position",
        component: Position,
    },
    {
        //职位详情
        path: "/positiondetails/:id",
        name: "PositionDetails",
        component: PositionDetails,
    },
    {
        //公司
        path: "/company",
        name: "Company",
        component: Company,
    },
    {
        //公司介绍
        path: "/companyintroduction/:id",
        name: "CompanyIntroduction",
        component: CompanyIntroduction,
    },
    {
        //沟通过、已投递、面试、感兴趣
        path: "/ditchpassage",
        name: "DitchPassage",
        component: DitchPassage,
    },
    {
        //我的在线简历
        path: "/onlineresume",
        name: "OnlineResume",
        component: OnlineResume,
    },
    {
        // 对我感兴趣、看过我
        path: "/interestedinme",
        name: "InterestedInMe",
        component: InterestedInMe,
    },
    {
        //用户协议
        path: "/useragreement",
        name: "UserAgreement",
        component: UserAgreement,
    },
    {
        // 资质公示
        path: "/publicity",
        name: "Publicity",
        component: Publicity,
    },
    {
        // 消息
        path: "/message",
        name: "Message",
        component: Message,
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({
        y: 0,
    }),
});

export default router;